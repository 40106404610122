import Aiday1 from "../images/2.png";

function Page2() {
 
  return (
    <div className="pages">
      <div>
        <img className="pageImg" src={Aiday1} alt="" />
      </div>
      <h2>"Твоя улыбка ослепительнее солнца поэтому я хочу называть тебя своим солнышком"</h2>
    </div>
  );
}

export default Page2;
