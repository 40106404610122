  import Aiday1 from "../images/3.png";

function Page3() {
 

  return (
    <div className="pages">
      <div>
        <img className="pageImg" src={Aiday1} alt="" />
      </div>
    <h2>"Как бы ты не взрослела я буду тебя баловать как маленькую"</h2>    
      </div>
  );
}

export default Page3;
