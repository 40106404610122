
import Aiday1 from "../images/5.png";

function Page5() {


  return (
    <div className="pages">
      <div>
        <img className="pageImg" src={Aiday1} alt="" />
      </div>
      <h2>"Я никак не могу набраться смелости, чтобы глядя тебе в глаза сказать, что люблю тебя, поэтому мне остается пока только писать тебе об этом в письмах."</h2>
    </div>
  );
}

export default Page5;
