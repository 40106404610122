import Aiday from "../images/1.png";

function Page1() {
  return (
    <div className="pages">
      <div>
        <img className="pageImg" src={Aiday} alt="" />
      </div>
      <h2>
        "У тебя очень красивое лицо, но твоя внутренняя красота еще ярче и
        удивительнее."
      </h2>
    </div>
  );
}

export default Page1;
