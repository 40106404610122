import Aiday1 from "../images/6.png";

function Page6() {
 

  return (
    <div className="pages">
      <div>
        <img className="pageImg" src={Aiday1} alt="" />
      </div>
      <h2>"В тебе есть что-то воодушевляющее. Ты добродушная, веселая, с тобой приятно находиться рядом, даже в самые тяжелые и напряженные моменты моей жизни."</h2>
    </div>
  );
}

export default Page6;
