import Aiday1 from "../images/4.png";

function Page4() {
  return (
    <div className="pages">
      <div>
        <img className="pageImg" src={Aiday1} alt="" />
      </div>
      <h2>"В тебе есть какое-то сияние, какой-то внутренний свет. Я счастлив, когда нахожусь рядом с тобой."</h2>
    </div>
  );
}

export default Page4;
