import Aiday1 from "../images/7.png";

function Page7() {


  return (
    <div className="pages">
      <div>
        <img className="pageImg" src={Aiday1} alt="" />
      </div>
      <h2>"Скольким людям посчастливилось влюбиться в человека, который одновременно является их лучшим другом, и чтобы эта любовь была взаимна? А мне повезло. Ты ‒ мой лучший друг, моя опора, моя радость, мое счастье."</h2>
    </div>
  );
}

export default Page7;
