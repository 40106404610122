import Aiday1 from "../images/8.png";

function Page8() {
  return (
    <div className="pages">
      <div>
        <img className="pageImg" src={Aiday1} alt="" />
      </div>
      <h2>"Люблю тебя P.S"</h2>
    </div>
  );
}

export default Page8;
